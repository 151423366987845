import {Address} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {Price} from "@/modules/genprox/models/Shared";

export interface UserContext {
    id: string,
    name: string,
    context: Contexts,
    logo: string,
}
// TODO: Eliminate enums from app. Create union types instead.
// export type Contexts = 'investor' | 'private-investor' | 'fund-manager' | 'private-fund-manager' | 'fund' | 'company'

export enum Contexts {
    investor = 'investor', //legal entity with investor options
    privateInvestor = 'private-investor', // natural person with investor options
    fundManager = 'fund-manager',
    fundManagerPrivate = 'private-fund-manager',
    fund = 'fund',
    company = 'company',
    advisorPanel = 'advisor-panel'
}

export interface LegalEntity {
    city?: string,
    country?: string,
    description?: string,
    id?: string,
    logo?: string,
    name?: string,
    email?: string,
    registerNumber?: string,
    establishmentDate?: string,
    statisticalNumber?: string,
    taxNumber?: string,
    address?: Address
}

export interface FundData {
    programme: string | null,
    commencementDate: string | null,
    investmentAgreementDate: string | null,
    endOfInvestmentPeriod: string | null,
    fundClosingDate: string | null,
    capitalisation: Price | null,
    fundManager: Price | null,
    publicInvestor: Price | null,
    privateInvestor: Price | null,
    investmentBudget: Price | null,
    operationalBudget: Price | null
}

export interface NaturalPerson {
    name?: string,
    logo?: string,
    email?: string,
    phone?: string,
    description?: string,
}

export interface Employee {
    name?: string,
    logo?: string,
    email?: string,
    phone?: string,
    description?: string,
    position?: string,
}

export interface Fund extends LegalEntity{

}
/**
 * Management Team member has all NaturalPerson & Employee params
 */
export interface MTMember extends Employee {
    managementTeam?: boolean;
    naturalPersonId?: string;
}

export interface FundManager extends LegalEntity{

}
