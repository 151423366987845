var render = function () {
  var _vm$crmData$industrie, _vm$crmData$focusArea;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "profile-crm-data"
  }, [_c('sygni-container-title', [_vm._v(" " + _vm._s(_vm.viewType === 'owner' ? 'My data' : 'CRM Data') + " ")]), _vm.isCRMDataVisible ? [_vm.investmentPotentialAsNumber !== -1 ? _c('sygni-rating-bar', {
    attrs: {
      "max": _vm.maxInvestmentPotential,
      "value": _vm.investmentPotentialAsNumber,
      "label": 'INVESTMENT POTENTIAL'
    }
  }) : _vm._e(), _vm.InvestmentKnowledgeAsNumber !== -1 ? _c('sygni-rating-bar', {
    attrs: {
      "max": _vm.maxInvestmentKnowledge,
      "value": _vm.InvestmentKnowledgeAsNumber,
      "label": 'INVESTMENT KNOWLEDGE'
    }
  }) : _vm._e(), ((_vm$crmData$industrie = _vm.crmData.industries) === null || _vm$crmData$industrie === void 0 ? void 0 : _vm$crmData$industrie.length) > 0 ? _c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("INDUSTRIES")]), _c('div', {
    staticClass: "industry__container"
  }, _vm._l(_vm.industries, function (industry) {
    return _c('div', {
      key: industry,
      staticClass: "industry"
    }, [_vm._v(" " + _vm._s(industry.label) + " ")]);
  }), 0)]) : _vm._e(), ((_vm$crmData$focusArea = _vm.crmData.focusArea) === null || _vm$crmData$focusArea === void 0 ? void 0 : _vm$crmData$focusArea.length) > 0 ? _c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("FOCUS AREA")]), _c('div', {
    staticClass: "focus-area__container"
  }, _vm._l(_vm.crmData.focusArea, function (focusArea) {
    return _c('div', {
      key: focusArea,
      staticClass: "focus-area"
    }, [_vm._v(" " + _vm._s(focusArea) + " ")]);
  }), 0)]) : _vm._e(), _vm.crmData.notes ? _c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("NOTES")]), _vm._v(" " + _vm._s(_vm.crmData.notes) + " ")]) : _vm._e()] : _vm._e(), _c('sygni-rounded-button', {
    class: ['outline black edit-button', !_vm.displayEditBtn ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        return _vm.editProfile();
      }
    }
  }, [_vm._v("Edit")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }