var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-active-products-table"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-table sygni-b-secondary-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "code",
          on: {
            "click": function click($event) {
              return _vm.onCodeClick(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s((_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.code ? rowData.item.code : 'NONE') + " ")])];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "source-name"
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(rowData) {
        return [rowData.item.type.toUpperCase() === 'LLC_SHARES' ? [_vm._v(" LLC Shares ")] : _vm._e(), rowData.item.type.toUpperCase() === 'JOINT_STOCK_SHARES' ? [_vm._v(" Joint-stock Shares ")] : _vm._e(), rowData.item.type.toUpperCase() !== 'LLC_SHARES' && rowData.item.type.toUpperCase() !== 'JOINT_STOCK_SHARES' ? [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.type)) + " ")] : _vm._e()];
      }
    }, {
      key: "cell(agreementDate)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.agreementDate)) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "outline round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm._f("capitalizeFirstLetter")(_vm.$t(rowData.item.status))) + " ")])], 1)];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        return [rowData.item.value ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.value.value, 2)) + " " + _vm._s(rowData.item.value.currency) + " ")]) : _vm._e()];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }