


































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import {Transaction} from "@/modules/genprox/modules/profile/models/transaction.interface";
import {Statues} from "@/shared/interfaces/Statues";
import {Prop} from "vue-property-decorator";

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner }
})
export default class ProfileTransactionHistoryTable extends SygniTable<Transaction> {

  @Prop({}) viewType!: 'investor' | 'owner';

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'code', sortable: false, label: 'CODE'},
    { key: 'source', sortable: false, borderless: true, label: 'SOURCE'},
    { key: 'type', sortable: false, borderless: true , label: 'TYPE' },
    { key: 'agreementDate', sortable: false, label: 'AGREEMENT DATE' },
    { key: 'value', sortable: false, label: "VALUE" },
  ];

  constructor() {
    super();
  }

  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }


  setQuery(){
    this.$store.commit(this.setTransactionsHistoryQueryCommit, this.localTableQuery );
  }

  async getItems(){
    const items = await this.$store.dispatch(this.getTransactionHistoryAction, this.getTransactionHistoryActionPayload);
    this.$store.commit('profile/setTransactionsTableItems', items);
  }


  statusClass(status: Statues) {
    switch(status) {
      case(Statues.active): return 'primary';
      case(Statues.invited): return 'success';
      case(Statues.awaiting): return 'success';
      case(Statues.pending): return 'black';
      case(Statues.rejected): return 'danger';
      case(Statues.declined): return 'disabled';
    }
    return '';
  }

  get getTransactionHistoryAction(): string {
    if(this.viewType === 'owner') {
      return 'profile/getActiveUserTransactionsHistory'
    } else if (this.viewType === 'investor'){
      return 'investors/getInvestorTransactionHistory'
    }
    return '';
  }

  get getTransactionHistoryActionPayload(): string {
    if(this.viewType === 'owner') {
      return null;
    } else if (this.viewType === 'investor'){
      return this.$route.params.id;
    }
    return '';
  }

  get setTransactionsHistoryQueryCommit(): string {
    if(this.viewType === 'owner') {
      return 'profile/setTransactionsHistoryQuery';
    } else if (this.viewType === 'investor'){
      return 'investors/setGetTransactionsQuery';
    }
    return '';
  }
}

